import { tableColumnEntitiesAtom } from '@/atoms/queries/tableColumns';
import { TableDataControls } from '@/components/controls/TableDataControls';
import { PlayerAggsFilters } from '@/components/dataLocker/PlayerAggsFilters';
import { PageTitle } from '@/components/head/PageTitle';
import { LayoutWithFilters } from '@/components/layout/LayoutWithFilters';
import { PlayerAggsTableWithPagination } from '@/components/tables/PlayerAggsTable';
import { ScopeProvider } from 'jotai-scope';
import { useTranslation } from 'react-i18next';
import { ScopedTableWithDefaultColumns } from '@/components/tables/ScopedTableWithDefaultColumns';
import { unwrappedPlayerDataDefaultColumnsAtom } from '@/atoms/dataLocker/player/table';
import { TableId } from '@/types/table';
import { useAtomValue, useSetAtom } from 'jotai';
import { playersNormalisedAggregatesUrl } from '@/query/url';
import { useCsvDownload } from '@/hooks/exporting/useCsvDownload';
import { playerAggsFilterParamsAtom } from '@/atoms/queries/player/playerAggData';
import { resetFiltersToLastAppliedFiltersAtom } from '@/atoms/filters/resetFilters';
import { EntityAggsContainer } from '../EntityAggsContainer';

const CsvDownload = ({ pageTitle }: { pageTitle: string }) => {
  const playerAggsFilterParams = useAtomValue(playerAggsFilterParamsAtom);
  useCsvDownload(pageTitle, playersNormalisedAggregatesUrl(playerAggsFilterParams));
  return null;
};

const TableWithControls = () => {
  const { t } = useTranslation(['navigation']);

  return (
    <>
      <TableDataControls heading={t(`viewing.table`, { ns: 'visualisation' })} />
      <PlayerAggsTableWithPagination />
    </>
  );
};

const TableWithControlsWrapper = ({ pageTitle }: { pageTitle: string }) => (
  <EntityAggsContainer>
    <CsvDownload pageTitle={pageTitle} />
    <TableWithControls />
  </EntityAggsContainer>
);

export const PlayerAggsPage = () => {
  const { t } = useTranslation(['navigation']);
  const pageTitle = t('pageTitles.dataLocker', {
    entity: t('player.one', { ns: 'entity' }),
  });

  const resetFiltersToLastAppliedFilters = useSetAtom(resetFiltersToLastAppliedFiltersAtom);

  return (
    <ScopeProvider atoms={[tableColumnEntitiesAtom]}>
      <PageTitle>{pageTitle}</PageTitle>
      {/* NOTE - LayoutWithFilters needs to be inside ScopedTableWithDefaultColumns as it has a dependency on the order by field and order direction. */}
      <ScopedTableWithDefaultColumns
        defaultColumnsAtom={unwrappedPlayerDataDefaultColumnsAtom}
        tableId={TableId.PLAYER_AGGS}
      >
        <LayoutWithFilters onClickMobileClose={resetFiltersToLastAppliedFilters} filters={<PlayerAggsFilters />}>
          <TableWithControlsWrapper pageTitle={pageTitle} />
        </LayoutWithFilters>
      </ScopedTableWithDefaultColumns>
    </ScopeProvider>
  );
};
