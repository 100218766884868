import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  filtersToPersistAtom,
  filterChangesToApplyAtom,
  lastAppliedFiltersAtom,
} from '@/atoms/filters/persistedFilters';
import { useDeepEffect } from '@/hooks/useDeepEffect';
import { resetFiltersToLastAppliedFiltersAtom } from '@/atoms/filters/resetFilters';
import { hasPlayerAggsCompAndSeasonFilterParamsAtom } from '@/atoms/queries/player/playerAggData';
import { clientOrderByAtom, clientOrderDirectionAtom, orderByAtom, orderDirectionAtom } from '@/atoms/table';

export const useApplyFiltersButton = () => {
  const clientOrderBy = useAtomValue(clientOrderByAtom);
  const clientOrderDirection = useAtomValue(clientOrderDirectionAtom);
  const setOrderBy = useSetAtom(orderByAtom);
  const setOrderDirection = useSetAtom(orderDirectionAtom);
  const filtersToPersist = useAtomValue(filtersToPersistAtom);
  const filterChangesToApply = useAtomValue(filterChangesToApplyAtom);
  const [lastAppliedFilters, setLastAppliedFilters] = useAtom(lastAppliedFiltersAtom);

  const hasLoadedDefaultFilterParams = useAtomValue(hasPlayerAggsCompAndSeasonFilterParamsAtom);
  const resetFiltersToLastAppliedFilters = useSetAtom(resetFiltersToLastAppliedFiltersAtom);

  useDeepEffect(() => {
    /* don't do anything on initial rendering until the default values have been loaded,
      need the lastAppliedFilters === null check here to not keep running the setter
      once the atom is no longer null */
    if (hasLoadedDefaultFilterParams && lastAppliedFilters === null) {
      setLastAppliedFilters(filtersToPersist);
    }
  }, [hasLoadedDefaultFilterParams, lastAppliedFilters]);

  return {
    onCancel: resetFiltersToLastAppliedFilters,
    onApply: () => {
      // keep server side sort atoms up to date when applying new filters
      if (clientOrderBy) {
        setOrderBy(clientOrderBy);
        setOrderDirection(clientOrderDirection);
      }
      setLastAppliedFilters(filterChangesToApply);
    },
  };
};
