import { MINUTES_PLAYED_COLUMN } from './table';

export const PLAYER_DATA_LOCKER_DEFAULT_COLUMNS = [
  'playerLink',
  MINUTES_PLAYED_COLUMN,
  'player.age',
  'player.height',
  'aggregates.npXgPer90',
  'aggregates.npXgPerShot',
  'aggregates.opXaPer90',
  'aggregates.shotsPer90',
  'aggregates.obvPer90',
  'aggregates.shotObvPer90',
  'aggregates.dribbleAndCarryObvPer90',
  'aggregates.defensiveActionObvPer90',
  'aggregates.passObvPer90',
  'aggregates.deepProgressionsPer90',
  'aggregates.touchesInsideBoxPer90',
  'aggregates.turnoversPer90',
  'aggregates.foulsPer90',
  'aggregates.foulsWonPer90',
  'aggregates.aerialRatio',
  'aggregates.successfulAerialsPer90',
  'aggregates.pressuresPer90',
  'aggregates.tacklesAndInterceptionsPer90',
  'aggregates.dribblePastsPer90',
] as const;
