import { round } from 'lodash';
import { Metric, MetricDistribution } from '@statsbomb/parachute-types';
import { KeysToCamelCase } from '@/types/generic';
import { formatRadarValue } from '@/utils/radar';
import { MetricData, MetricDistributionRange } from '@/types/metric';
import { camelToSnakeCase } from './queries';

const isMetricData = (item: MetricData | undefined): item is MetricData => !!item;

export const decimalPlaces = (minValue: number, maxValue: number) => {
  const diff = Math.abs(minValue - maxValue);
  if (diff < 1) {
    // When the scale difference is less than one, we want to show 2 significant figures for the numbers on our scale.
    // The Radar component takes decimal places, so we need to convert significant figures to decimal places.
    // To do this, round the diff 2 significant figures, get the length of that, and then subtract 2 to account for the '0.' bit of the rounded diff.
    return diff.toPrecision(2).length - 2;
  }

  if (diff < 10) {
    return 1;
  }

  return 0;
};

export const combineMetricData = (
  configTotalMetrics: KeysToCamelCase<Metric>[],
  metricDistros: KeysToCamelCase<MetricDistribution>[],
) =>
  configTotalMetrics
    .map(definition => {
      const { key, reverseScale, percentage: isPercentage } = definition;

      const matchedObject = metricDistros.find(metric => metric.metricKey === key);

      // TODO (PPC-274: How do we do error handling)
      if (!matchedObject) {
        // eslint-disable-next-line no-console
        console.warn(`No metric distribution was found for metric definition: ${key}`);
        return undefined;
      }

      const range = reverseScale
        ? {
            minValue: matchedObject?.metricP95,
            maxValue: matchedObject?.metricP5,
          }
        : {
            minValue: matchedObject?.metricP5,
            maxValue: matchedObject?.metricP95,
          };

      const minValue = formatRadarValue(range.minValue, isPercentage);
      const maxValue = formatRadarValue(range.maxValue, isPercentage);
      return {
        key: matchedObject.metricKey,
        keyNormalised: matchedObject.metricKeyNormalised,
        isPercentage,
        minValue,
        maxValue,
        decimalPlaces: decimalPlaces(minValue, maxValue),
      };
    })
    .filter(isMetricData);

export const getMetricKeyFromColumnKey = (columnKey: string) =>
  camelToSnakeCase(columnKey.split('.')[1].replace('Per90', ''));

export const getMetricDistributionPrecision = (percentage: boolean, rangeToStepsRatio: number) => {
  if (percentage) return { step: 0.1, precision: 1 };
  if (rangeToStepsRatio > 0.1) return { step: 1, precision: 0 };
  if (rangeToStepsRatio > 0.01) return { step: 0.1, precision: 1 };
  if (rangeToStepsRatio > 0.001) return { step: 0.01, precision: 2 };
  return { step: 0.001, precision: 3 };
};

// The maximum amount of steps a player metric distribution slider can handle
const METRIC_SLIDER_MAX_STEPS = 200;

export const getPlayerMetricDefinitions = (
  playerMetrics: KeysToCamelCase<Metric>[],
  playerMetricDistributions: KeysToCamelCase<MetricDistribution>[],
) => {
  const playerMetricDistributionDefinitions = playerMetricDistributions.map(metric => {
    const playerMetric = playerMetrics.find(m => m.key === metric.metricKey);
    const percentage = playerMetric?.percentage || false;
    const reverseScale = playerMetric?.reverseScale || false;

    const rangeToStepsRatio = (metric.metricP95 - metric.metricP5) / METRIC_SLIDER_MAX_STEPS;
    const { step, precision } = getMetricDistributionPrecision(percentage, rangeToStepsRatio);
    const metricP5 = round(percentage ? metric.metricP5 * 100 : metric.metricP5, precision);
    const metricP95 = round(percentage ? metric.metricP95 * 100 : metric.metricP95, precision);

    return {
      ...metric,
      metricP5,
      metricP95,
      percentage,
      reverseScale,
      precision,
      step,
    };
  });

  return playerMetricDistributionDefinitions;
};

export const getMetricDistributionsParams = (metricDistributions: MetricDistributionRange[]) => {
  const metricDistributionParams = metricDistributions.reduce(
    (acc, { name, min, max }) => {
      const gte = min !== null ? { [`output.${name}`]: min } : {};
      const lte = max !== null ? { [`output.${name}`]: max } : {};

      return {
        lte: { ...acc.lte, ...lte },
        gte: { ...acc.gte, ...gte },
      };
    },
    { gte: {}, lte: {} },
  );

  return metricDistributionParams;
};
