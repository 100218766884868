import { Suspense } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { isMetricFiltersModalOpenAtom } from '@/atoms/dataLocker/player/modal';
import { ButtonIcon, Card, CardBody } from '@statsbomb/kitbag-components';
import { playerMetricDistributionDefinitionsAtom, selectedMetricsAtom } from '@/atoms/dataLocker/metrics';
import { ErrorBoundary } from '@sentry/react';
import { MetricDistributionDefinition } from '@/types/metric';
import { GeneralErrorBoundaryFallback } from '../errorBoundary/ErrorBoundaryFallback';
import { MetricFiltersModal } from '../modal/MetricFiltersModal/MetricFiltersModal';
import { MetricDistributionSlider } from '../sliders/MetricDistributionSlider';

const OpenMetricFiltersModalButton = () => {
  const { t } = useTranslation('filters');
  const setIsMetricFiltersModalOpen = useSetAtom(isMetricFiltersModalOpenAtom);

  return (
    <ButtonIcon
      icon="PlusAdd"
      size="small"
      variant="secondary"
      displayText="right"
      onClick={() => setIsMetricFiltersModalOpen(true)}
    >
      {t('addMetricFilter')}
    </ButtonIcon>
  );
};

const MetricFiltersStatus = () => {
  const { t } = useTranslation('filters');

  return (
    <div className="h-full flex items-center min-h-36">
      <div className="w-full">
        <Card>
          <CardBody
            statusType="info"
            statusTitle={t('metricInfo.title')}
            statusDescription={t('metricInfo.description')}
          />
          <div className="flex justify-center mt-8">
            <OpenMetricFiltersModalButton />
          </div>
        </Card>
      </div>
    </div>
  );
};

const PlayerDataLockerMetricFiltersBase = ({
  playerMetricDistributionDefinitions = [],
}: {
  playerMetricDistributionDefinitions?: MetricDistributionDefinition[];
}) => {
  const playerDataLockerSelectedMetrics = useAtomValue(selectedMetricsAtom);
  const displayStatus = !playerDataLockerSelectedMetrics.length;

  return (
    <>
      {displayStatus ? (
        <MetricFiltersStatus />
      ) : (
        <div>
          <OpenMetricFiltersModalButton />
        </div>
      )}
      {playerMetricDistributionDefinitions.length > 0 &&
        playerDataLockerSelectedMetrics.map(metric => {
          const metricDistribution = playerMetricDistributionDefinitions.find(
            metricDistribution => `aggregates.${metricDistribution.metricKeyNormalised}` === metric,
          );

          if (!metricDistribution) {
            // eslint-disable-next-line no-console
            console.error(`unable to find matching metric distribution for metric key: ${metric}`);
            return null;
          }

          const { metricP5, metricP95, step, reverseScale, precision, percentage } = metricDistribution;

          return (
            <MetricDistributionSlider
              key={metric}
              metric={metric}
              minValue={metricP5}
              maxValue={metricP95}
              step={step}
              precision={precision}
              percentage={percentage}
              reverseScale={reverseScale}
            />
          );
        })}
      <MetricFiltersModal />
    </>
  );
};

export const PlayerDataLockerMetricFiltersWithData = () => {
  const playerMetricDistributionDefinitions = useAtomValue(playerMetricDistributionDefinitionsAtom);

  return (
    <PlayerDataLockerMetricFiltersBase playerMetricDistributionDefinitions={playerMetricDistributionDefinitions} />
  );
};

export const PlayerDataLockerMetricFilters = () => (
  <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
    <Suspense fallback={<PlayerDataLockerMetricFiltersBase />}>
      <PlayerDataLockerMetricFiltersWithData />
    </Suspense>
  </ErrorBoundary>
);
