import { ButtonIcon } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { NoVideoLicenceTooltip } from './NoVideoLicenceTooltip';

const NavigateToVideoButtonBase = ({ hasVideoLicence }: { hasVideoLicence?: boolean }) => {
  const { t } = useTranslation('video');
  const buttonText = t('playVideo');

  return (
    <ButtonIcon
      as="span"
      size="small"
      variant="ghost"
      icon="Play"
      title={hasVideoLicence ? buttonText : undefined}
      disabled={!hasVideoLicence}
    >
      {buttonText}
    </ButtonIcon>
  );
};

export const NavigateToVideoButton = ({
  linkParams,
  hasVideoLicence,
}: {
  linkParams: string;
  hasVideoLicence?: boolean;
}) =>
  hasVideoLicence ? (
    <Link to={`./video${linkParams}`} className="invisible">
      <NavigateToVideoButtonBase hasVideoLicence />
    </Link>
  ) : (
    <NoVideoLicenceTooltip>
      <div className="invisible pointer-events-none">
        <NavigateToVideoButtonBase hasVideoLicence={false} />
      </div>
    </NoVideoLicenceTooltip>
  );
