import { round } from 'lodash';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Slider } from '@statsbomb/kitbag-components';
import { getTranslationColumns } from '@/utils/translations';
import { snakeToCamel } from '@/utils/queries';
import { selectedMetricDistributionsAtom } from '@/atoms/dataLocker/metrics';
import { SliderOnChange } from '@statsbomb/kitbag-components/types/components/Inputs/Slider/types';
import { useMetricDistributionSliderLabel } from '@/hooks/useMetricDistributionSliderLabel';
import { isNullish } from '@/utils/general';

export const MetricDistributionSlider = ({
  metric,
  minValue,
  maxValue,
  step,
  precision,
  percentage,
  reverseScale,
}: {
  metric: string;
  minValue: number;
  maxValue: number;
  step: number;
  precision: number;
  percentage: boolean;
  reverseScale?: boolean;
}) => {
  const { t } = useTranslation('metrics');
  const { translationKey } = getTranslationColumns(snakeToCamel(metric));
  const [selectedMetricDistributions, setSelectedMetricDistributions] = useAtom(selectedMetricDistributionsAtom);

  const range = maxValue - minValue;
  // adding 8% extra to the range to allow for the slider to be dragged over the min/max values
  const extra = round((range / 100) * 8, precision);
  // makes going over the min/max values more intentional
  const padding = extra / 2;

  const sliderMin = reverseScale ? 0 : minValue;
  const sliderMax = reverseScale ? range : maxValue;

  const minUnsetValue = round(sliderMin - extra, precision);
  const maxUnsetValue = round(sliderMax + extra, precision);

  const selectedMetric = selectedMetricDistributions.find(({ name }) => name === metric);
  const savedMin = selectedMetric?.min;
  const savedMax = selectedMetric?.max;

  const formatSavedValue = (value: number | null | undefined) =>
    !isNullish(value) && percentage ? round(value * 100, precision) : value;

  const savedMinValue = formatSavedValue(savedMin) ?? minValue - extra;
  const savedMaxValue = formatSavedValue(savedMax) ?? maxValue + extra;

  // convert saved values to reverse slider scale values
  const offset = maxValue - savedMaxValue;
  const savedRange = savedMaxValue - savedMinValue;
  const sliderSelectedMin = reverseScale ? sliderMin + offset : savedMinValue;
  const sliderSelectedMax = reverseScale ? sliderSelectedMin + savedRange : savedMaxValue;

  // functions to convert a slider values to the values we actually want to save
  const toSavedValue = (value: number) => round(reverseScale ? maxValue - value : value, precision);
  const percentToFraction = (value: number) => (percentage ? value / 100 : value);

  const minValueToSave = percentToFraction(minValue);
  const maxValueToSave = percentToFraction(maxValue);

  const getMinToSave = (newMin: number | undefined) => {
    // handle slider returning undefined
    if (isNullish(newMin)) return null;

    const newSavedMin = toSavedValue(newMin);
    // handle dragged over the min value but NOT enough to select "open end"
    if (newSavedMin < minValue && newSavedMin > minValue - padding) return minValueToSave;
    // handle dragged over the min value enough to select "open end"
    if (newSavedMin <= minValue - padding) return null;
    // prevent the min handle from going over the max value
    if (newSavedMin > maxValue) return maxValueToSave;
    // convert percentage values to decimal if necessary
    return percentToFraction(newSavedMin);
  };

  const getMaxToSave = (newMax: number | undefined) => {
    if (isNullish(newMax)) return null;

    const newSavedMax = toSavedValue(newMax);
    if (newSavedMax > maxValue && newSavedMax < maxValue + padding) return maxValueToSave;
    if (newSavedMax >= maxValue + padding) return null;
    if (newSavedMax < minValue) return minValueToSave;
    return percentToFraction(newSavedMax);
  };

  const onChange: SliderOnChange = (_, range) => {
    // This if statement is to satisfy the SliderOnChange type. In reality `range` is always an array in a multi slider
    /* istanbul ignore if */
    if (!Array.isArray(range)) return;
    const [min, max] = range;
    const minToSave = getMinToSave(reverseScale ? max : min);
    const maxToSave = getMaxToSave(reverseScale ? min : max);

    const updatedMetricDistribution = {
      name: metric,
      min: minToSave,
      max: maxToSave,
    };

    const newMetricDistributions = selectedMetricDistributions.map(m =>
      m.name === metric ? updatedMetricDistribution : m,
    );

    setSelectedMetricDistributions(newMetricDistributions);
  };

  const onDelete = () => {
    const newMetricDistributions = selectedMetricDistributions.filter(({ name }) => name !== metric);
    setSelectedMetricDistributions(newMetricDistributions);
  };

  const title = t(translationKey);

  const minDisplayValue = reverseScale ? savedMaxValue : savedMinValue;
  const maxDisplayValue = reverseScale ? savedMinValue : savedMaxValue;
  const formattedLabel = useMetricDistributionSliderLabel(
    minValue,
    maxValue,
    minDisplayValue,
    maxDisplayValue,
    reverseScale,
  );

  const minLabel = reverseScale ? maxValue : minValue;
  const maxLabel = reverseScale ? minValue : maxValue;
  const labels = [
    { label: '<', position: minUnsetValue },
    { label: minLabel.toString(), position: sliderMin },
    { label: maxLabel.toString(), position: sliderMax },
    { label: '>', position: maxUnsetValue },
  ];

  return (
    <Slider
      id={metric}
      label={title}
      labels={labels}
      min={minUnsetValue}
      max={maxUnsetValue}
      step={step}
      value={[sliderSelectedMin, sliderSelectedMax]}
      valueLabel={formattedLabel}
      onChange={onChange}
      onDelete={onDelete}
      // @ts-ignore - TODO (EPT-6001: Fix slider mock component)
      test
    />
  );
};
