import { Entity } from '@/types/entity';
import { Section } from '@/types/generic';
import { kebabToCamel } from '@/utils/queries';
import { useLocation } from 'react-router-dom';

/* TODO (PPC-737: Explore ways of getting entity, entityId) */
export const useGetEntity = (): {
  entity: Section;
  entityId: number | null;
  subEntity: Entity | null;
} => {
  const { pathname } = useLocation();
  const splitPath = pathname.split('/');

  const entity = kebabToCamel(splitPath[2]) as Section;
  const entityId = entity === 'dataLocker' ? null : Number(splitPath[3]);
  const subEntity = entity === 'dataLocker' ? (splitPath[3] as Entity) : null;

  return { entity, entityId, subEntity };
};
