import { selectedVideoIdsAtom } from '@/atoms/video';
import { useFormatVideoEvents } from '@/hooks/useFormatVideoEvents';
import { Alert, Typography } from '@statsbomb/kitbag-components';
import { Playlist, VideoEvent } from '@statsbomb/video-client-js';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { markersDescriptionsAtom } from '@/atoms/eventMarkers';
import { selectedVisEventsAtom } from '@/atoms/vis/selection';
import { VideoProvider } from '../Providers';

const VideoPlayerBase = () => {
  const { t } = useTranslation('video');
  const selectedVideoIds = useAtomValue(selectedVideoIdsAtom);
  const videoEventMarkersDescriptions = useAtomValue(markersDescriptionsAtom);
  const videoEvents = useFormatVideoEvents(selectedVideoIds, videoEventMarkersDescriptions);
  const selectedVisEvents = useAtomValue(selectedVisEventsAtom);
  const selectedVisEventsCount = selectedVisEvents?.length || 0;
  const selectedVideoIdsCount = selectedVideoIds?.length || 0;
  const displayLicensingAlert = !!selectedVisEventsCount && selectedVisEventsCount !== selectedVideoIdsCount;
  const unavailableVideosCount = selectedVisEventsCount - selectedVideoIdsCount;

  return (
    <div className="p-4">
      <VideoProvider>
        <VideoEvent autoPlay events={videoEvents || []} />
        {displayLicensingAlert && (
          <div className="pt-4">
            <Alert isFullWidth type="info">
              {t('noLicenceCount', { count: unavailableVideosCount })}
            </Alert>
          </div>
        )}
        <div className="max-h-[300px] md:max-h-[700px] overflow-auto">
          {videoEvents && videoEvents?.length > 0 && <Playlist enableEditMode enableSelectAll />}
        </div>
      </VideoProvider>
    </div>
  );
};

export const VideoPlayer = () => {
  const { t } = useTranslation('video');

  return (
    <Suspense
      fallback={
        <Typography variant="bodyMedium" className="p-4">
          {t('loadingVideo')}
        </Typography>
      }
    >
      <VideoPlayerBase />
    </Suspense>
  );
};
