import { useTranslation } from 'react-i18next';
import { useResetSelectionLayer } from '@/hooks/useResetSelectionLayer';
import { useSetAtom } from 'jotai';
import { selectedVideoIdsBaseAtom } from '@/atoms/video';
import { useNavigateToPreview } from '@/hooks/useNavigateToPreview';
import { ButtonIcon } from '@statsbomb/kitbag-components';
import { NoVideoLicenceTooltip } from './NoVideoLicenceTooltip';

interface PlayVideoButtonProps {
  uuid: string;
  eventId: string;
  hasVideoLicence?: boolean;
}

const PlayVideoButtonBase = ({ uuid, eventId, hasVideoLicence }: PlayVideoButtonProps) => {
  const { t } = useTranslation('video');
  const setSelectedVideoIds = useSetAtom(selectedVideoIdsBaseAtom);
  const resetSelectionLayer = useResetSelectionLayer();

  const navigateToPreview = useNavigateToPreview();

  const handleSelect = () => {
    resetSelectionLayer();
    setSelectedVideoIds([{ eventId, videoEventId: uuid }]);

    navigateToPreview();
  };

  return (
    <ButtonIcon
      variant="ghost"
      onClick={hasVideoLicence ? handleSelect : undefined}
      icon="Play"
      size="small"
      title={t('playVideo')}
      disabled={!hasVideoLicence}
    />
  );
};

export const PlayVideoButton = ({ uuid, eventId, hasVideoLicence }: PlayVideoButtonProps) => {
  const Button = <PlayVideoButtonBase uuid={uuid} eventId={eventId} hasVideoLicence={hasVideoLicence} />;

  return hasVideoLicence ? Button : <NoVideoLicenceTooltip>{Button}</NoVideoLicenceTooltip>;
};
