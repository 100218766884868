import { ButtonWithFlyout } from '@statsbomb/kitbag-components';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export const GameWatchButtonWithFlyout = ({
  onClickHandler,
  onOutsideClickHandler,
  isOpen,
  isDisabled = false,
  children,
}: {
  onClickHandler: () => void;
  onOutsideClickHandler: () => void;
  isOpen: boolean;
  isDisabled?: boolean;
  children: ReactNode;
}) => {
  const { t } = useTranslation('games');

  return (
    <ButtonWithFlyout
      size="small"
      displayText="right"
      variant="ghost"
      icon="ChevronDown"
      onClick={onClickHandler}
      onOutsideClick={onOutsideClickHandler}
      placement="bottom-start"
      title={t('watchVideo')}
      isOpen={isOpen}
      disabled={isDisabled}
    >
      {children}
    </ButtonWithFlyout>
  );
};
