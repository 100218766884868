import { atom } from 'jotai';
import { playerMarkersToggleDisabledAtom } from '../player/vis';
import { teamMarkersToggleDisabledAtom } from './teamVis';
import { dataLockerMarkersToggleDisabledAtom } from '../dataLocker/vis';
import { sectionAtom } from '../general';

const markersToggleDisableMapAtom = atom(() => ({
  player: playerMarkersToggleDisabledAtom,
  team: teamMarkersToggleDisabledAtom,
  dataLocker: dataLockerMarkersToggleDisabledAtom,
  game: atom(async () => false), // temp as game section doesn't use this atom yet
}));

export const markersToggleDisabledAtom = atom(get => {
  const section = get(sectionAtom);
  const isDisabled = section ? get(get(markersToggleDisableMapAtom)[section]) : false;
  return isDisabled;
});
