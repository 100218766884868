import { atom } from 'jotai';
import { groupTableColumns } from '@/utils/table';
import { objSnakeToCamel } from '@/utils/queries';
import { MetricDistributionDefinition, MetricDistributionRange } from '@/types/metric';
import { getMetricDistributionsParams, getPlayerMetricDefinitions } from '@/utils/metrics';
import { rawTableColumnsAtom } from '../queries/tableColumns';
import { playerMetricDistributionsAtom, playerMetricsAtom } from '../queries/player/playerRadar';

export const selectedMetricDistributionsAtom = atom<MetricDistributionRange[]>([]);

export const selectedMetricsAtom = atom(
  get => {
    const selectedMetricDistributions = get(selectedMetricDistributionsAtom);
    const selectedMetrics = selectedMetricDistributions.map(({ name }) => name);

    return selectedMetrics;
  },
  (get, set, metrics: string[]) => {
    const selectedMetricDistributions = get(selectedMetricDistributionsAtom);

    const newSelectedMetricDistributions = metrics.map(metric => {
      const existingMetric = selectedMetricDistributions.find(({ name }) => name === metric);

      return existingMetric || { name: metric, min: null, max: null };
    });

    set(selectedMetricDistributionsAtom, newSelectedMetricDistributions);
  },
);

// converts selected metric distributions to query params
export const metricDistributionParamsAtom = atom(get => {
  const selectedMetricDistributions = get(selectedMetricDistributionsAtom);
  const metricDistributionParams = getMetricDistributionsParams(selectedMetricDistributions);

  return metricDistributionParams;
});

export const playerMetricGroupsAtom = atom(async get => {
  const { data } = await get(rawTableColumnsAtom);

  const playerMetricTableColumns = data
    .filter(
      metric => metric.category_branch.key === 'player_metric' && metric.category_leaf.key !== 'player_player_metric',
    )
    .map(objSnakeToCamel);

  const playerMetricGroups = groupTableColumns(playerMetricTableColumns);

  return playerMetricGroups;
});

export const playerMetricDistributionDefinitionsAtom = atom(async get => {
  const [playerMetrics, playerMetricDistributions] = await Promise.all([
    get(playerMetricsAtom),
    get(playerMetricDistributionsAtom),
  ]);

  const playerMetricDistributionDefinitions: MetricDistributionDefinition[] = getPlayerMetricDefinitions(
    playerMetrics,
    playerMetricDistributions,
  );

  return playerMetricDistributionDefinitions;
});
