import { competitionsAtom } from '@/atoms/queries/competitions';
import { rawCyclesAtom } from '@/atoms/queries/cycles';
import { eventNamesAtom } from '@/atoms/queries/eventData';
import { metricsAtom } from '@/atoms/queries/metric';
import { playersAtom } from '@/atoms/queries/players';
import { seasonsAtom } from '@/atoms/queries/seasons';
import { teamsAtom } from '@/atoms/queries/teams';
import { identityProviderUserIdUserNameMapAtom } from '@/atoms/queries/user';
import { useAtomValue } from 'jotai';
import { loadable } from 'jotai/utils';

/**
 * This hook can be use to ensure that we eagerly fetch reference data required
 * across the entire application. Doing this means that time to load pages within
 * the application is reduced as the data required has already been fetched and
 * cached.
 */
export const useApplicationReferenceData = () => {
  useAtomValue(loadable(competitionsAtom));
  useAtomValue(loadable(seasonsAtom));
  useAtomValue(loadable(rawCyclesAtom));
  useAtomValue(loadable(teamsAtom));
  useAtomValue(loadable(playersAtom));
  useAtomValue(loadable(eventNamesAtom));
  useAtomValue(loadable(metricsAtom));
  useAtomValue(loadable(identityProviderUserIdUserNameMapAtom));
};
