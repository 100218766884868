import { Tooltip } from '@statsbomb/kitbag-components';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export const NoVideoLicenceTooltip = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('video');

  return (
    <Tooltip delay={200} content={<div className="max-w-64">{t('noLicence')}</div>}>
      {children}
    </Tooltip>
  );
};
