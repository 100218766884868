import { identityProviderUserIdUserNameMapAtom } from '@/atoms/queries/user';
import { filterSetsAtom } from '@/atoms/queries/userConfigs';
import { SYSTEM_WIDE_USER_ID } from '@/consts/userConfigs';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

export const useFilterSets = () => {
  const { t } = useTranslation(['general']);
  const { user } = useKitbagAuth();
  const currentUserId = user?.sub;
  const identityProviderUserIdUserNameMap = useAtomValue(identityProviderUserIdUserNameMapAtom);

  const filterSetOwnerName = (identityProviderUserId: string) => {
    if (identityProviderUserId === SYSTEM_WIDE_USER_ID) return 'Hudl Statsbomb';
    if (currentUserId === identityProviderUserId) return t('me');
    return identityProviderUserIdUserNameMap[identityProviderUserId] || t('deactivated');
  };

  const filterSets = useAtomValue(filterSetsAtom).map(filterSet => ({
    ...filterSet,
    ownerName: filterSetOwnerName(filterSet.identityProviderUserId),
  }));

  return filterSets;
};
