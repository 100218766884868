import React, { Fragment, Suspense } from 'react';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { ConfirmationDialog, Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { isWidgetFiltersModalOpenAtom, widgetConfigAtom } from '@/atoms/report/widget';
import { selectedCompetitionsAtom } from '@/atoms/filters/highLevel/competitions';
import { selectedSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { selectedTeamsAsyncAtom } from '@/atoms/filters/highLevel/teams';
import { selectedPlayersAtom } from '@/atoms/filters/highLevel/players';
import { convertOptionsToLabels } from '@/utils/array';
import { ReportWidgetConfig } from '@/types/widget';
import { playerSelectedGamesAtom } from '@/atoms/filters/player/playerGames';
import { teamSelectedGamesAtom } from '@/atoms/filters/team/teamGames';
import { deselectedGamesAtom } from '@/atoms/filters/highLevel';
import { ConfigFilterKey } from '@/types/userConfigs';
import {
  useFormatChecklist,
  useFormatEvents,
  useFormatGameCount,
  useFormatGameType,
  useFormatGender,
  useFormatMetric,
  useFormatPeriod,
  useFormatPitchZones,
  useFormatPositions,
} from './WidgetFiltersModal.helper';

const entityGamesAtomMap = {
  player: playerSelectedGamesAtom,
  team: teamSelectedGamesAtom,
  dataLocker: atom([]),
  game: atom([]),
};

const WidgetFiltersModalBase = ({
  children,
  isLoading,
  title,
}: {
  children?: React.ReactNode;
  isLoading?: boolean;
  title: string;
}) => {
  const { t } = useTranslation('general');
  const setIsWidgetFiltersModalOpenAtom = useSetAtom(isWidgetFiltersModalOpenAtom);

  return (
    <ConfirmationDialog
      id="widget-filters"
      title={title}
      isOpen
      onConfirm={() => setIsWidgetFiltersModalOpenAtom(false)}
      cancelLabel=""
      confirmLabel={t('close')}
    >
      {isLoading ? (
        <div className="h-20 w-full flex items-center justify-center">
          <Typography variant="bodyMedium">{t('loading', { ns: 'general' })}</Typography>
        </div>
      ) : (
        children
      )}
    </ConfirmationDialog>
  );
};

const WidgetFiltersModalContent = ({ widget }: { widget: ReportWidgetConfig }) => {
  const { t } = useTranslation(['general', 'widget', 'events', 'metrics', 'entity', 'filters', 'games', 'preferences']);
  const { entity, filters, title } = widget;

  const selectedCompetitions = useAtomValue(selectedCompetitionsAtom);
  const selectedSeasons = useAtomValue(selectedSeasonsAtom);
  const selectedTeams = useAtomValue(selectedTeamsAsyncAtom);
  const selectedPlayers = useAtomValue(selectedPlayersAtom);
  const selectedGames = useAtomValue(entityGamesAtomMap[entity]);
  const deselectedGames = useAtomValue(deselectedGamesAtom);

  const competitionNames = convertOptionsToLabels(selectedCompetitions);
  const seasonsNames = convertOptionsToLabels(selectedSeasons);
  const teamNames = convertOptionsToLabels(selectedTeams);
  const playerNames = convertOptionsToLabels(selectedPlayers);

  // using Omit over Partial to force devs to consider if a new filter should be displayed in the modal
  const filterDisplayConfig: Omit<
    Record<ConfigFilterKey, { name: string; description: string }>,
    // gameRange not shown in this modal as we show the value of the period or date range and not which one was selected
    | 'gameRange'
    // These are savable in a filter set but don't apply to a widget (yet) so force them not to show:
    | 'preferredFoot'
    | 'minAge'
    | 'maxAge'
    | 'minHeight'
    | 'maxHeight'
    | 'minMinutes'
    | 'maxMinutes'
    | 'metricDistributions'
  > = {
    metric: {
      name: t('metric.one'),
      description: useFormatMetric(filters.metric),
    },
    competitionIds: {
      name: t('competition.other', { ns: 'entity' }),
      description: useFormatChecklist(competitionNames),
    },
    seasonIds: {
      name: t('season.other', { ns: 'entity' }),
      description: useFormatChecklist(seasonsNames),
    },
    teamIds: {
      name: t('team.other', { ns: 'entity' }),
      description: useFormatChecklist(teamNames),
    },
    playerIds: {
      name: t('player.other', { ns: 'entity' }),
      description: useFormatChecklist(playerNames),
    },
    deselectedGameIds: {
      name: t('specificGames', { ns: 'filters' }),
      description: useFormatGameCount(selectedGames.length, deselectedGames.length),
    },
    positions: {
      name: t('position', { ns: 'filters' }),
      description: useFormatPositions(filters.positions),
    },
    periodRange: {
      name: t('period', { ns: 'filters' }),
      description: useFormatPeriod(filters.periodRange),
    },
    gameType: {
      name: t('venue', { ns: 'filters' }),
      description: useFormatGameType(filters.gameType),
    },
    eventNames: {
      name: t('eventTypes', { ns: 'filters' }),
      description: useFormatEvents(filters.eventNames, 'fields.event.type'),
    },
    outcomes: {
      name: t('outcomes', { ns: 'filters' }),
      description: useFormatEvents(filters.outcomes, 'fields.event.attributes.outcome'),
    },
    bodyParts: {
      name: t('bodyParts', { ns: 'filters' }),
      description: useFormatEvents(filters.bodyParts, 'fields.event.attributes.bodyPart'),
    },
    keeperActions: {
      name: t('keeperActions', { ns: 'filters' }),
      description: useFormatEvents(filters.keeperActions, 'keys.event.attributes'),
    },
    passHeights: {
      name: t('passHeights', { ns: 'filters' }),
      description: useFormatEvents(filters.passHeights, 'fields.event.attributes.passHeight'),
    },
    startDate: {
      name: t('startDate', { ns: 'filters' }),
      description: filters.startDate,
    },
    endDate: {
      name: t('endDate', { ns: 'filters' }),
      description: filters.endDate,
    },
    pitchStartZones: {
      name: t('pitchStartZones', { ns: 'filters' }),
      description: useFormatPitchZones(filters.pitchStartZones),
    },
    pitchEndZones: {
      name: t('pitchEndZones', { ns: 'filters' }),
      description: useFormatPitchZones(filters.pitchEndZones),
    },
    gender: {
      name: t('data.gender.label', { ns: 'preferences' }),
      description: useFormatGender(filters.gender),
    },
  };

  const filtersToDisplay = Object.values(filterDisplayConfig).filter(filter => filter.description);

  return (
    <WidgetFiltersModalBase title={title}>
      <div className="grid grid-cols-[auto_1fr] gap-2">
        {filtersToDisplay.map(filter => (
          <Fragment key={filter.name}>
            <Typography variant="headingSmall" as="p">
              {`${filter.name}:`}
            </Typography>
            <Typography variant="bodyMedium" as="p">
              {filter.description}
            </Typography>
          </Fragment>
        ))}
      </div>
    </WidgetFiltersModalBase>
  );
};

const WidgetFiltersModalWithData = () => {
  const widgetConfig = useAtomValue(widgetConfigAtom);

  return (
    widgetConfig && (
      <Suspense fallback={<WidgetFiltersModalBase title={widgetConfig.title} isLoading />}>
        <WidgetFiltersModalContent widget={widgetConfig} />
      </Suspense>
    )
  );
};

export const WidgetFiltersModal = () => {
  const isWidgetFiltersModalOpen = useAtomValue(isWidgetFiltersModalOpenAtom);

  return isWidgetFiltersModalOpen && <WidgetFiltersModalWithData />;
};
