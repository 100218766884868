import { atomWithQuery, atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { EventWithRels, GameWithRels, Team, User, UserDetails } from '@statsbomb/parachute-types';
import { atom } from 'jotai';
import { unwrappedUserDefaultTeamIdAtom, userDefaultTeamIdAtom } from '@/atoms/user';
import { fetchClientAtom } from './client';

export const userDetailsAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['user'] as const;
  const queryFn = async () => {
    const { fetch } = get(fetchClientAtom);
    const response = await fetch(`/user-details`);
    return response as UserDetails;
  };
  return { queryKey, queryFn };
});

export const defaultUserTeamAndPlayerIdsAtom = atomWithQuery(get => {
  const queryKey = ['defaultTeamAndPlayerIds', get(unwrappedUserDefaultTeamIdAtom)] as const;
  const queryFn = async ({ queryKey: [_, teamId] }: { queryKey: typeof queryKey }) => {
    const { fetch } = get(fetchClientAtom);

    const [playerData] = (await fetch(`/team/${teamId}/players?limit=1`)) as EventWithRels[];
    const playerId = playerData.player.player_id;

    return { teamId, playerId };
  };
  const enabled = get(unwrappedUserDefaultTeamIdAtom) !== undefined;
  return { queryKey, queryFn, enabled };
});

const rawDefaultTeamInfoAtom = atomWithSuspenseQuery(get => {
  const { fetch } = get(fetchClientAtom);
  const queryKey = ['defaultTeamInfo', get(userDefaultTeamIdAtom)] as const;

  const queryFn = async ({ queryKey: [_, defaultTeamId] }: { queryKey: typeof queryKey }) => {
    const teamId = await defaultTeamId;
    if (!teamId) return null;

    return (await fetch(`/teams?eq[team_id]=${teamId}`)) as Promise<Team[]>;
  };

  return { queryKey, queryFn };
});

export const defaultTeamInfoAtom = atom(async get => (await get(rawDefaultTeamInfoAtom))?.data?.[0]);

export const rawDefaultTeamGamesAtom = atomWithSuspenseQuery(get => {
  const { fetch } = get(fetchClientAtom);
  const queryKey = ['defaultTeamGames', get(unwrappedUserDefaultTeamIdAtom)] as const;

  const queryFn = async ({ queryKey: [_, teamId] }: { queryKey: typeof queryKey }) => {
    if (!teamId) return null;

    return (await fetch(`/team/${teamId}/games`)) as Promise<GameWithRels[]>;
  };

  return { queryKey, queryFn };
});

const entityUsersAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['entityUsers'] as const;
  const queryFn = async () => {
    const { fetch } = get(fetchClientAtom);
    const response = await fetch(`/entity/users`);
    return response as User[];
  };
  return { queryKey, queryFn };
});

export const identityProviderUserIdUserNameMapAtom = atom(async get => {
  const users = await get(entityUsersAtom);
  return users.data.reduce<Record<string, string>>((acc, user) => {
    acc[user.identity_provider_user_id] = user.name;
    return acc;
  }, {});
});
